import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  MenuItem,
  Box,
  Container,
  Menu,
  Grow,
  Paper,
  Popper,
  MenuList,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import React, { useState, useEffect, useRef, useContext } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import Logo from './../../component/Logo'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import TwitterIcon from '@material-ui/icons/Twitter'
import { SiDiscord } from 'react-icons/si'
import { sortAddress, sortPublicAddress } from 'src/utils'
import { AuthContext } from 'src/context/Auth'
const headersData = [
  // {
  //   label: "RECENT",
  //   href: "/recent",
  // },
  // {
  //   label: "STATS",
  //   href: "/stats",
  // },
  // {
  //   label: "ABOUT",
  //   href: "/about",
  // },
  {
    label: 'HOW TO OPEN',
    href: '/how-to-play',
  },
  {
    label: 'FAQs',
    href: '/faq',
  },
  // {
  //   label: "ODDS",
  //   href: "/odds",
  // },
]

const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontSize: '12px',
    lineHeight: '24px',
    fontWeight: '400',
    borderRadius: 0,
    minWidth: 'auto',
    color: '#FFFFFF',
    height: '31px',
    padding: '0px 20px',
    letterSpacing: '1px',
    fontFamily: "'Roboto'",
    '@media (max-width: 900px)': {
      fontStyle: 'normal',
      letterSpacing: '-0.6px',
      lineHeight: '24px',
      color: '#FFF',
      padding: '15px !important',
      height: '51px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
    },
    '&:active': {
      color: '#f784f8',
    },
    '&:hover': {
      color: '#f784f8',
    },
  },
  menuButton1: {
    width: '100%',
  },
  toolbar: {
    display: 'flex',
    padding: '20px 0',
    justifyContent: 'space-between',
    height: '100%',
    '@media (max-width: 900px)': {
      paddingLeft: '75px',
      paddingRight: '20px',
      height: '100%',
    },
  },
  drawerContainer: {
    padding: '20px 0px ',
    height: '100%',
    background: '#000',
    width: '200px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '@media(max-width:320px)': {
      width: '170px',
    },
  },
  drawericon: {
    color: '#000',
    position: 'absolute',
    top: '0px',
    right: '-10px',
    fontSize: '25px',
  },
  logoImg: {
    width: '75px',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    '@media (max-width: 500px)': {
      margin: ' 11px 1px 3px 0px',
      width: '52px',
    },
  },
  menuMobile: {
    fontSize: '14px',
    fontWeight: '400',
    fontStyle: 'normal',
    letterSpacing: '-0.6px',
    lineHeight: '1.75',
    color: '#fff',
    width: '100%',
    borderBottom: '1px solid #3e3e3e',
    padding: '10px',
    '@media (max-width: 500px)': {
      padding: '7px 0',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  containerHeight: {
    height: '100%',
  },
  mainHeader: {
    justifyContent: 'space-between',
    padding: '0px',
  },
  submenu: {
    borderTop: '3px solid #300760',
    top: '25px !important',
  },
  menuIconButton: {
    display: 'flex',
    alignItems: 'center',
    '& a': {
      margin: '0px 10px',
      '& svg': {
        fontSize: '25px',
        '&:active': {
          color: '#f784f8',
        },
        '&:hover': {
          color: '#f784f8',
        },
      },
    },
  },
  drawerIconButton: {
    width: '100%',
    '& .iconbuttonbox': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingBottom: '15px',
      '& a': {
        margin: '0px 10px',
        '& svg': {
          fontSize: '25px',
          '&:active': {
            color: '#f784f8',
          },
          '&:hover': {
            color: '#f784f8',
          },
        },
      },
    },
    '& .buttonbox': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
}))
export default function Header() {
  const auth = useContext(AuthContext)
  const location = useLocation()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClose1 = () => {
    setAnchorEl(null)
  }
  const {
    menuMobile,
    menuButton,
    menuButton1,
    toolbar,
    drawerContainer,
    drawericon,
    containerHeight,
    mainHeader,
    submenu,
    menuIconButton,
    drawerIconButton,
  } = useStyles()
  const history = useHistory()

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  })

  const { mobileView, drawerOpen } = state

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }))
    }

    setResponsiveness()

    window.addEventListener('resize', () => setResponsiveness())
  }, [])

  const [open1, setOpen1] = useState({ community: false, user: false })
  const anchorRef = { community: useRef(null), user: useRef(null) }

  // const handleToggle = (name) => {
  //   setOpen1({ ...open1, [name]: !open1[name] });
  // };

  const handleClose2 = (event, name) => {
    if (
      anchorRef[name].current &&
      anchorRef[name].current.contains(event.target)
    ) {
      return
    }

    setOpen1({ ...open1, [name]: false })
  }

  function handleListKeyDown(event, name) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen1({ ...open1, [name]: false })
    }
  }

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0">
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          <Grid
            container
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            style={{ paddingLeft: '0px' }}
          >
            {getMenuButtons()}
            <Box className={menuIconButton}>
              <IconButton target="_blank" href="https://discord.gg/TmyAyzCK">
                <SiDiscord />
              </IconButton>
              <IconButton
                target="_blank"
                href="https://twitter.com/lootiesnft?s=21&t=nGS1UKpgRElvz7jtFcLssQ"
              >
                <TwitterIcon />
              </IconButton>
              {auth?.provider && auth?.walletKey ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => auth.disconnectWallet()}
                >
                  Disconnect{' '}
                  <p
                    style={{
                      marginBottom: '0',
                      paddingBottom: '0',
                      marginLeft: '5px',
                    }}
                  >
                    {sortAddress(auth?.walletKey)}
                  </p>
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => auth.connectWallet()}
                >
                  Connect Wallet
                </Button>
              )}
            </Box>
            <Popper
              open={open1.community}
              anchorEl={anchorRef.community.current}
              role={undefined}
              transition
              disablePortal
              className={submenu}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener
                      onClickAway={(e) => handleClose2(e, 'community')}
                    >
                      <MenuList
                        autoFocusItem={open1}
                        id="menu-list-grow"
                        onKeyDown={(e) => handleListKeyDown(e, 'community')}
                      >
                        <MenuItem component={Link} to="/fungy-token">
                          Fungy Token
                        </MenuItem>
                        <MenuItem component={Link} to="/faq">
                          Discussion
                        </MenuItem>
                        <MenuItem>Voting</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Grid>
        </Toolbar>
      </Container>
    )
  }

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }))
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }))

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: 'right',
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {getDrawerChoices()}
            <Box className={drawerIconButton} pt={1} pb={1}>
              <Box className="iconbuttonbox">
                <IconButton target="_blank" href="https://discord.gg/TmyAyzCK">
                  <SiDiscord />
                </IconButton>
                <IconButton
                  target="_blank"
                  href="https://twitter.com/lootiesnft?s=21&t=nGS1UKpgRElvz7jtFcLssQ"
                >
                  <TwitterIcon />
                </IconButton>
              </Box>
              <Box className="buttonbox">
                {auth?.provider && auth?.walletKey ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => auth.disconnectWallet()}
                  >
                    Disconnect{' '}
                    <p
                      style={{
                        marginBottom: '0',
                        paddingBottom: '0',
                        marginLeft: '5px',
                      }}
                    >
                      {sortAddress(auth?.walletKey)}
                    </p>
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => auth.connectWallet()}
                  >
                    Connect Wallet
                  </Button>
                )}
              </Box>
            </Box>
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        <Grid container>
          <Grid item xs={10}></Grid>
          <Grid item xs={2}>
            <IconButton
              className={drawericon}
              {...{
                edge: 'start',
                color: 'inherit',
                'aria-label': 'menu',
                'aria-haspopup': 'true',
                onClick: handleDrawerOpen,
              }}
            >
              <MenuIcon
                width="60px"
                height="60px"
                style={{ color: 'rgb(159 243 177)', fontSize: '30px' }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    )
  }

  const getDrawerChoices = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: Link,
              className: menuButton1,
            }}
          >
            <MenuItem className={menuMobile}>{label}</MenuItem>
          </Button>
        </>
      )
    })
  }

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  )

  const getMenuButtons = () => {
    return headersData.map(({ label, href }) => {
      return (
        <>
          <Button
            {...{
              key: label,
              color: 'inherit',
              to: href,
              component: Link,
              className: menuButton,
            }}
          >
            {label}
          </Button>
        </>
      )
    })
  }

  return (
    <>
      <AppBar
        position={history.location.pathname !== '/' ? 'relative' : 'absolute'}
        elevation={0}
        style={{ backgroundColor: '#ccc0', border: 'none' }}
      >
        <Container
          maxWidth={history.location.pathname !== '/' ? 'lg' : 'fixed'}
          className={containerHeight}
        >
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose1}
      >
        <MenuItem>
          <Link to="/profile">My Profile</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/user">My Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/resell-nft">Resell Nft</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/create-nft">Create NFT</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/notification">Notification</Link>
        </MenuItem>
        <MenuItem>
          <Link to="/search">Search</Link>
        </MenuItem>
      </Menu>
    </>
  )
}
