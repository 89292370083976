export const NetworkContextName = 'NETWORK'
export const ACTIVE_NETWORK = 42
export const tokenContract = '0xE4861c8C1A80250e1e280F7F7bbf84736146b867'
export const MarketplaceContract = '0xf5DE7F4Ee0C4063a1e6f139fEa2eB92c2D153653'
export const NftTokenAddress = '0x4846666e4013A48647be98AF3FDE33251e679bd2'
export const houseWalletPublicKey =
  '7yGwPWAbFcwH4c6L5RJLi2cMTW3TptsBMD4gV3nBVV6d'
export const feeWalletPublicKey = '5x9TvNb4ai8jqfHTZkBDaWLQM7pCcRyiyLQKzSfViB61'
export const pricedata = [
  {
    image: 'images/Image0.05.png',
    image2: 'images/image.png',
    amount: '0.05 sol',
  },
  {
    image: 'images/Image0.1.png',
    image2: 'images/image.png',
    amount: '0.1 sol',
  },
  {
    image: 'images/Image0.25.png',
    image2: 'images/image.png',
    amount: '0.25 sol',
  },
  {
    image: 'images/Image0.5.png',
    image2: 'images/image.png',
    amount: '0.5 sol',
  },
  {
    image: 'images/Image0.75.png',
    image2: 'images/image.png',
    amount: '0.75 sol',
  },
  {
    image: 'images/Image1.png',
    image2: 'images/image.png',
    amount: '1.0 sol',
  },
]
