import React, { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import apiConfig from 'src/APIconfig/ApiConfig'

import { toast } from 'react-toastify'
import { sortPublicAddress, sortAddress } from 'src/utils'
import { PublicKey, Transaction } from '@solana/web3.js'
export const AuthContext = createContext()

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('creatturAccessToken', accessToken)
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`
  } else {
    localStorage.removeItem('creatturAccessToken')
    delete axios.defaults.headers.common.Authorization
  }
}

function checkLogin() {
  const accessToken = window.localStorage.getItem('creatturAccessToken')
  return accessToken ? true : false
}

export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin())
  const [userData] = useState({})
  const [lootBoxData, setLootBoxData] = useState([])

  const [isLoadingLootBox, setIsLoadingBox] = useState(false)
  const [isLoadingTransactions, setisLoadingTransactions] = useState(false)
  const [transactionList, setTransactionList] = useState([])
  const [userBalanceSOL, setUserBalanceSOL] = useState(0)
  const [walletKey, setWalletKey] = useState('')
  const [provider, setProvider] = useState('')
  const getProvider = () => {
    if ('solana' in window) {
      // @ts-ignore
      const provider = window.solana
      if (provider.isPhantom) return provider
    }
  }
  const connectWallet = async () => {
    // @ts-ignore
    const { solana } = window

    if (solana) {
      try {
        const response = await solana.connect()
        console.log('wallet account ', response.publicKey.toString())
        setWalletKey(response.publicKey.toString())
      } catch (err) {
        // { code: 4001, message: 'User rejected the request.' }
      }
    }
  }
  const disconnectWallet = async () => {
    // @ts-ignore
    const { solana } = window

    if (walletKey && solana) {
      await solana.disconnect()
      setWalletKey(undefined)
      window.sessionStorage.removeItem('walletAddress')
      window.sessionStorage.removeItem('token')
    }
  }

  // detect phantom provider exists
  useEffect(() => {
    const provider = getProvider()
    if (provider) setProvider(provider)
    else setProvider(undefined)
  }, [])

  const connectWalletHandler = async (address) => {
    try {
      const res = await axios({
        method: 'POST',
        url: apiConfig.connectWallet,
        data: {
          walletAddress: address,
        },
      })

      if (res.data.statusCode === 200) {
        window.sessionStorage.setItem('token', res.data.result.token)
        getUserWalletBalanceHandler(address)
        if (!window.sessionStorage.getItem('walletAddress', address)) {
          toast.info(`Welcome back ${sortAddress(address)}`)
        }

        window.sessionStorage.setItem('walletAddress', address)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getLootBoxHandler = async () => {
    setIsLoadingBox(true)
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getMystryBoxList,
      })
      if (res.data.statusCode === 200) {
        setIsLoadingBox(false)
        setLootBoxData(res.data.result)
      }
    } catch (error) {
      setIsLoadingBox(false)
      console.log(error)
    }
  }
  const getTransactionListHandler = async () => {
    setisLoadingTransactions(true)
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.transactionList,
      })
      if (res.data.statusCode === 200) {
        setTransactionList(res.data.result)
        setisLoadingTransactions(false)
      }
    } catch (error) {
      setisLoadingTransactions(false)
      console.log(error)
    }
  }
  const getUserWalletBalanceHandler = async (address) => {
    try {
      const res = await axios({
        method: 'GET',
        url: apiConfig.getBalance,
        params: {
          address: address,
        },
      })
      if (res.data.statusCode === 200) {
        setUserBalanceSOL(res.data.result)
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if (walletKey) {
      const interval = setInterval(
        () => getUserWalletBalanceHandler(walletKey),
        30000,
      )
      return () => {
        clearInterval(interval)
      }
    }
  }, [walletKey])

  useEffect(() => {
    getLootBoxHandler()
    getTransactionListHandler()
  }, [])

  useEffect(() => {
    if (walletKey) {
      connectWalletHandler(walletKey)
    }
  }, [walletKey])
  useEffect(() => {
    if (window.sessionStorage.getItem('walletAddress')) {
      connectWallet()
    }
  }, [window.sessionStorage.getItem('walletAddress')])

  let data = {
    userLoggedIn: isLogin,
    userData,
    isLoadingLootBox,
    lootBoxData,
    isLoadingTransactions,
    transactionList,
    userBalanceSOL,
    walletKey,
    provider,
    getTransactionListHandler: () => getTransactionListHandler(),
    connectWallet: () => connectWallet(),
    disconnectWallet: () => disconnectWallet(),
    userLogIn: (type, data) => {
      setSession(data)
      setIsLogin(type)
    },
  }

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  )
}
