export default {
  h1: {
    fontWeight: 500,
    fontSize: 35,
    fontFamily:"'Press Start 2P', cursive"
  },
  h2: {
    fontWeight: 500,
    fontSize: 30,
    fontFamily:"Arial"
  },
  h3: {
    fontWeight: 300,
    fontSize: 25,
    fontFamily:"'Roboto', sans-serif;"
  },
  h4: {
    fontWeight: 300,
    fontSize: 20,
    fontFamily:"'Roboto', sans-serif;"
  },
  h5: {
    fontWeight: 300,
    fontSize: 18,
    fontFamily:"'Roboto', sans-serif;"
  },
  h6: {
    fontWeight: 300,
    fontSize: 16,
    fontFamily:"'Poppins', sans-serif;",
  },
  overline: {
    fontWeight: 500,
  },
  button: {
    textTransform: "capitalize",
    borderRadius: 27,
  },
  body2: {
    fontSize: 14,
    color:"#fff",
    fontFamily:"'Poppins', sans-serif;"
  
  },
  body1: {
    fontSize: 12,
    fontFamily:"'Poppins', sans-serif;"
  },
};
