import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "./layouts/HomeLayout";

export const routes = [
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index")),
  },
  {
    exact: true,
    path: "/odds",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Odds")),
  },
  {
    exact: true,
    path: "/about",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/About")),
  },
  {
    exact: true,
    path: "/faq",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/FAQ")),
  },
  {
    exact: true,
    path: "/how-to-play",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/HowToPlay")),
  },
  {
    exact: true,
    path: "/recent",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/Recent")),
  },
  {
    exact: true,
    path: "/mint",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Mint/Mint")),
  },
  {
    exact: true,
    path: "/about-us",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/About")),
  },
  {
    exact: true,
    path: "/terms-and-conditions",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/TermsConditions")),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/StaticPages/PrivacyPolicy")),
  },
  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
