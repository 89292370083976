let baseurl = 'https://node-web3solana.mobiloitte.org'
let user = `${baseurl}/api/v1/user`

const apiConfig = {
  //USER
  connectWallet: `${user}/connectWallet`,
  getMystryBoxList: `${user}/getMystryBoxList`,
  getReward: `${user}/getReward/`,
  transactionList: `${user}/transactionList`,
  getBalance: `${user}/getBalance`,
}
export default apiConfig
