import React from 'react'
import {
  Grid,
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Link,
  Divider,
  IconButton,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import TwitterIcon from '@material-ui/icons/Twitter'
import { AiOutlineInstagram } from 'react-icons/ai'
import { FaFacebookF } from 'react-icons/fa'
import { SiDiscord } from 'react-icons/si'
import YouTubeIcon from '@material-ui/icons/YouTube'

const useStyles = makeStyles((theme) => ({
  IconBox: {
    display: 'flex',
    textAlign: 'center',
    justifyContent: 'space-evenly',
  },
  dot: {
    width: '31px',
    height: '31px',
    borderRadius: '50%',
    background: '#89AFFD',

    cursor: 'pointer',
  },
  logoImg: {
    // height: '44.5px',
    width: '35%',
    margin: ' 14px 15px 11px 0px',
    objectFit: 'contain',
    cursor: 'pointer',
    // zIndex: "999",
    // position: "relative",
    '@media (max-width: 767px)': {
      margin: '15px 1px 15px 1px',
      width: '140px',
      // zIndex: "999",
      // position: "relative"
    },
  },
  baseText: {
    textAlign: 'center',
    padding: '15px 0px 35px 0px',
    '& p': {
      color: '#FFFFFF',
    },
  },
  socialmediaicon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& a': {
      backgroundColor: '#89AFFD',
      margin: '8px',
      '&:hover': {
        backgroundColor: '#f784f8',
      },
    },
  },
  InternalPages: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& h6': {
      color: '#FFFFFF',
      cursor: 'pointer',
    },
  },
}))

export default function Liquidity() {
  const classes = useStyles()
  const history = useHistory()

  return (
    <>
      <Container maxWidth="lg">
        <Box mt={2} mb={2}>
          <Divider />
        </Box>
      </Container>
      <Container maxWidth="xs">
        <Box align="center" mt={2}>
          <Box onClick={() => history.push('/')}>
            <img
              src="/images/HeaderLogo.png"
              alt="Logo"
              className={classes.logoImg}
            />
          </Box>
        </Box>
        <Box className={classes.socialmediaicon}>
          <IconButton
            style={{ maarginRight: '8px' }}
            target="_blank"
            href="https://discord.gg/TmyAyzCK"
          >
            <SiDiscord />
          </IconButton>
          <IconButton
            style={{ maarginLeft: '8px' }}
            target="_blank"
            href="https://twitter.com/lootiesnft?s=21&t=nGS1UKpgRElvz7jtFcLssQ"
          >
            <TwitterIcon />
          </IconButton>
        </Box>
        <Box className={classes.baseText}>
          <Typography variant="body1">
            ©2022 Looties. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </>
  )
}
